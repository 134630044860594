import axiosClient from "./axiosClient";

const form = (formData, data, previousKey) => {
    if (data instanceof Object) {
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (value instanceof Object && !Array.isArray(value)) {
                return form(formData, value, key);
            }
            if (previousKey) {
                key = `${previousKey}[${key}]`;
            }
            if (Array.isArray(value)) {
                value.forEach(val => {
                    formData.append(`${key}[]`, JSON.stringify(val));
                });
            } else {
                formData.append(key, value);
            }
        });
    }
}

export const orderApi = {
    addOrder: (data, params) => {
        const url = "/orders";
        if(data){
            data.domain = 1;
        }
        else{
            data = {domain: 1};
        }
        let bodyFormData = new FormData();
        form(bodyFormData,data);
        return axiosClient.post(url, bodyFormData, { params });
    },
    addOrderP24: (data, params) => {
        const url = "/payments/p24/checkout";
        if(data){
            data.domain = 1;
        }
        else{
            data = {domain: 1};
        }
        let bodyFormData = new FormData();
        form(bodyFormData,data);
        return axiosClient.post(url, bodyFormData, { params });
    }
};
