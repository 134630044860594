import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import FastfoodSharpIcon from "@material-ui/icons/FastfoodSharp";
import NotificationsSharpIcon from "@material-ui/icons/NotificationsSharp";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SettingsIcon from "@material-ui/icons/Settings";

import MyOrder from "./MyOrder";
import Notification from "./Notification";
import Settings from "./Settings";
import Breadcrumb from "../../Components/Breadcrumbs";
import "./styles.scss";
import { useSelector } from "react-redux";
import RedirectPage from "../../Components/Redirect";
import Favorites from "./Favorites";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function getId(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    "&$selected": {
      backgroundColor: "#d1402a",
      color: "#FFFFFF !important",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const TabsCustomer = () => {
  const userReducer = useSelector((state) => state.userReducer);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!userReducer.name) {
    return <RedirectPage to="/" />;
  }

  return (
    <div className="tabs__Customer">
      <div className="container">
        <Breadcrumb title="Moje konto" />
      </div>

      <div className="container wrap">
        <div className="wrap__tab--left">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className="tabs__menu__customer"
          >
            <AntTab
              icon={<FastfoodSharpIcon />}
              className="tabs__menu__customer--children"
              label="Moje zamówienia"
              {...getId(0)}
            />
            <AntTab
              icon={<NotificationsSharpIcon />}
              className="tabs__menu__customer--children"
              label="Powiadomienia"
              {...getId(1)}
            />
            <AntTab
              icon={<FavoriteIcon />}
              className="tabs__menu__customer--children"
              label="Ulubione"
              {...getId(2)}
            />
            <AntTab
              icon={<SettingsIcon />}
              className="tabs__menu__customer--children"
              label="Ustawienia"
              {...getId(3)}
            />
          </Tabs>
        </div>
        <div className="wrap__tab--right">
          <TabPanel value={value} index={0}>
            <MyOrder />
          </TabPanel>
          {/* NOTIFY STATUS */}
          <TabPanel value={value} index={1}>
            <Notification />
          </TabPanel>
          {/* RESTAURANT FAVORITES ORDER */}
          <TabPanel value={value} index={2}>
            <Favorites/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Settings />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};
export default TabsCustomer;
