import p24Logo from "../Assets/Cart/pobrane.png";
import cashLogo from "../Assets/Cart/money.svg";
import {orderApi} from "../Api/orderApi";
import {addressApi} from "../Api/addressApi";
import {toast} from "react-toastify";

export const payment = {
    paymentsOnline: () => {
      return [
          {
              id: "p24",
              name: "Przelewy 24",
              description: "Przelew, karta, BLIK",
              route: "/P24",
              logo: p24Logo,
              method: ""
          }
      ];
    },
    paymentsCash: () => {
        return [
            {
                id: "cod",
                name: "Gotówka",
                description: "Zapłacę gotówką przy odbiorze",
                route: "/CashOnDelivery",
                logo: cashLogo,
                method: "Cash on Delivery"
            },
            {
                id: "codc",
                name: "Karta",
                description: "Zapłacę kartą przy odbiorze",
                route: "/CashOnDelivery",
                logo: null,
                method: "Cash on Delivery"
            }
        ];
    },
    paymentPickup: () => {
        return [
            {
                id: "pop",
                name: "Płatność przy odbiorze",
                description: "Kliknij aby zapłacić przy odbiorze",
                route: "/PayOnPickup",
                logo: cashLogo,
                method: 'Pay on Pickup'
            }
        ];
    },
    pay: (user, shippingPrice, carts, payment, address, successPayment) => {
        const type = address.shippingMethod;
        const foods = carts.map((item) => {
            return {
                id:item.id,
                price:item.food.price,
                quantity:item.quantity,
                food_id:item.food_id
            }
        });
        if(type === "store"){
            if(payment.id === "pop"){
                const data = {
                    id: null,
                    user_id: user.id,
                    order_status_id: 1,
                    tax: 0.0,
                    delivery_fee: 0.0,
                    foods: foods,
                    payment: {
                        id: null,
                        status: null,
                        method: payment.method
                    }
                };

                return orderApi.addOrder(data, {
                    api_token: user.api_token
                }).then((response) => {
                    successPayment(response);
                });
            }
            else if(payment.id === "p24"){
                const data = {
                    id: null,
                    user_id: user.id,
                    order_status_id: 1,
                    tax: 0.0,
                    delivery_fee: 0.0,
                    foods: foods,
                    payment: {
                        id: null,
                        status: null,
                        method: "P24 - Pickup"
                    },
                    url_return: `${window.location.origin.toString()}/po-platnosci`
                };

                return orderApi.addOrderP24(data,{
                    api_token: user.api_token,
                }).then((response) => {
                    successPayment(response);
                }).catch((e) => {
                    if(e?.response?.status === 403){
                        alert('Płatności Przelewy24 dla tej restauracji są w trakcie aktywacji. Aktywacja nastąpi w ciągu maksymalnie 5 dni. Zapraszamy do skorzystania z innej formy płatności.');
                    }
                    if( e.response ){
                        toast.error(e.response.data);
                    }
                });
            }
        }
        else{
            addressApi.addAddress({
                user_id: user.id,
                address: `${address.address} ${address.city}`,
                description: `${address.name} ${address.email} ${address.phone}`,
            },{api_token: user.api_token}).then((response) => {
                if(response.success){
                    const {id:deliveryId} = response.data;
                    const data = {
                        id: null,
                        user_id: user.id,
                        order_status_id: 1,
                        tax: 0.0,
                        delivery_fee: shippingPrice,
                        foods: foods,
                        payment: {
                            id: null,
                            status: null,
                            method: payment.method
                        },
                        delivery_address_id:deliveryId,
                        url_return: `${window.location.origin.toString()}/po-platnosci`
                    };
                    if(payment.id === "p24"){
                        data.payment.method = "P24 - Delivery"
                        return orderApi.addOrderP24(data,{
                            api_token: user.api_token,
                        }).then((response) => {
                            successPayment(response);
                        }).catch((e) => {
                            if(e?.response?.status === 403){
                                alert('Płatności Przelewy24 dla tej restauracji są w trakcie aktywacji. Aktywacja nastąpi w ciągu maksymalnie 5 dni. Zapraszamy do skorzystania z innej formy płatności.');
                            }
                            if( e.response ){
                                toast.error(e.response.data);
                            }
                        });
                    }
                    else{
                        return orderApi.addOrder(data, {
                            api_token: user.api_token
                        }).then((response) => {
                            successPayment(response);
                        });
                    }
                }
                else{
                    toast.error(response.message);
                }
            })
        }
    }
};
