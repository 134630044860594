import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import "./styles.scss";
import CartList from "./CartList";
import CartInfo from "./CartInfo";
import CartPayment from "./CartPayment";
import CartMoney from "./CartMoney";
import CartSuccess from "./CartSuccess";
import Breadcrumb from "../../Components/Breadcrumbs";
import {Formik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {payment as paymentAction} from "../../Models/Payment";
import {resetCart} from "../../actions";
import {toast} from "react-toastify";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    "&$selected": {
      backgroundColor: "#d1402a",
      color: "#FFFFFF !important",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const getId = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const validator = Yup.object().shape({
  shippingMethod: Yup.string().required("Pole jest wymagane"),
  email: Yup.string()
      .email("Niepoprawny format maila.")
      .when("shippingMethod",{
        is:"place",
        then: Yup.string().required("Email jest wymagany")
      }),
  name: Yup.string()
      .when("shippingMethod",{
        is:"place",
        then: Yup.string().required("Imię i nazwisko jest wymagane")
      }),
  address: Yup.string()
      .when("shippingMethod",{
        is:"place",
        then: Yup.string().required("Ulica i numer lokalu jest wymagane")
      }),
  city: Yup.string()
      .when("shippingMethod",{
        is:"place",
        then: Yup.string().required("Miejscowość jest wymagana")
      }),
  phone: Yup.string()
      .when("shippingMethod",{
        is:"place",
        then: Yup.string().required("Telefon jest wymagany")
      }),
});

const CartPage = () => {
    const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [value, setValue] = useState(0);
  const [payment, setPayment] = useState(null);
  const [shipping, setShipping] = useState(null);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const changeTabButton = (idTabs) => setValue(idTabs);
  const userReducer = useSelector(state => state.userReducer);
  const carts = useSelector((state) => state.cartReducer.cart);

  const successPayment = (response) => {
      if(response.url){
          dispatch(resetCart());
          window.location.replace(response.url);
      }
      else if(response.success){
          dispatch(resetCart());
          setSuccess(response.data);
      }
      else{
          toast.error(response.message);
      }
  }

  const onSubmit = async (values) => {
      paymentAction.pay(userReducer,shipping,carts,payment,values, successPayment);
  };

  return (
    <div className="cart_page fade-in">
      <div className="container">
        <Breadcrumb title="Koszyk" />

        <div className="tabs">
          <Tabs
            scrollButtons="off"
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChangeTab}
            aria-label="Vertical tabs example"
            className="tabs__menu"
          >
            <AntTab
              className="tabs__menu--children hover_tab"
              label="Zamówienie"
              disabled={success !== false}
              {...getId(0)}
            />
            <AntTab
              className="tabs__menu--children hover_tab"
              label="Dostawa"
              disabled={!(value > 1) || success !== false}
              {...getId(1)}
            />
            <AntTab
              className="tabs__menu--children hover_tab"
              label="Płatność"
              disabled
              {...getId(2)}
            />
          </Tabs>
        </div>
        {!success ? (
                <Formik
                    initialValues={{
                      name: userReducer.name,
                      address: userReducer.settings?.custom_fields?.address?.value ?? "",
                      city: "",
                      phone: userReducer.settings?.custom_fields?.phone?.value ?? "",
                      email: userReducer.email,
                      shippingMethod: "store"
                    }}
                    onSubmit={onSubmit}
                    validationSchema={validator}
                >
                  {({
                      handleSubmit,
                      errors,
                      values,
                      touched,
                      handleBlur,
                      handleChange,
                    }) => (
                      <div className="tabs_panel">
                        <div className="tabs_panel--left">
                          <TabPanel value={value} index={0}>
                            <CartList />
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <CartInfo values={values} errors={errors} FormHandleChange={handleChange} touched={touched} handleBlur={handleBlur} handleSubmit={handleSubmit} setPayment={setPayment}/>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <CartPayment handleSubmit={handleSubmit} setPayment={setPayment} values={values}  />
                          </TabPanel>
                        </div>
                        <div className="tabs_panel--right">
                          <CartMoney idPanel={value} changeTab={changeTabButton} formValues={values} setShipping={setShipping} shipping={shipping} />
                        </div>
                      </div>
                  )}
                </Formik>
        ) : (
          <CartSuccess order={success} />
        )}
      </div>
    </div>
  );
};

export default CartPage;
