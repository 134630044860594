import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Opinion from "../../Components/Opinion";
import MenuRestaurant from "../../Components/MenuRestaurant";
import Breadcrumb from "../../Components/Breadcrumbs";

// Assets
import Note from "../../Assets/Home/note.svg";
import TopImg from "../../Assets/Home/top.jpg";
import Return from "../../Assets/Restaurant/return.svg";
import Phone from "../../Assets/Restaurant/phone.svg";

// Styles
import "./styles.scss";
import restaurantApi from "../../Api/restaurantApi";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import styled from 'styled-components';
import {getPrice} from "../../helpers/order";

const RestaurantInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  p{
    font-size: 12px;

  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ResInformation = (props) => {
  const { imgSrc, name, content, cardAddress } = props;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={`${cardAddress ? "https://www.google.com/maps/" : "#"}`}
      // eslint-disable-next-line react/jsx-no-target-blank
      target={`${cardAddress ? "_blank" : ""}`}
      style={{ textDecoration: "none" }}
    >
      <Card classes={{ root: "restaurant__card" }}>
        <CardHeader
          action={
            imgSrc ? (
              <div className="resIcon" style={{ display: "flex" }}>
                <img src={imgSrc} alt="" />
              </div>
            ) : null
          }
          title={name}
          subheader={content}
          classes={{
            root: "restaurant__card",
            action: "restaurant__action",
          }}
        />
      </Card>
    </a>
  );
};

const Restaurant = () => {
  const { id } = useParams();
  const reviews = useSelector(
    (state) => state.reviewReducer.restaurant_reviews
  );
  const [value, setValue] = useState(0);
  const [dataRestaurant, setDataRestaurant] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getOpeningDay = (day, from, to, isClosed) => {
    let dayInPolish = "";
    if(!from || !to){
      return null;
    }
    if(day === 'monday'){
      dayInPolish = "Poniedziałek";
    }
    else if(day === 'tuesday'){
      dayInPolish = "Wtorek";
    }
    else if(day === 'wednesday'){
      dayInPolish = "Środa";
    }
    else if(day === 'thursday'){
      dayInPolish = "Czwartek";
    }
    else if(day === 'friday'){
      dayInPolish = "Piątek";
    }
    else if(day === 'saturday'){
      dayInPolish = "Sobota";
    }
    else if(day === 'sunday'){
      dayInPolish = "Niedziela";
    }
    if(!isClosed){
      return `${dayInPolish} ${from} - ${to}`;
    }
    return `${dayInPolish} - zamknięte`;
  }

  const fetchRestaurant = async (restaurantId) => {
    const restaurant = await restaurantApi.getSingle(restaurantId);
    setDataRestaurant(restaurant.data);
  };

  useEffect(() => {
    fetchRestaurant(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="restaurant">
      {!_.isEmpty(dataRestaurant) ? (
        <div className="container">
          <Breadcrumb title={dataRestaurant.name} />
          <AppBar
            position="static"
            classes={{ colorPrimary: "appBar", root: "appBar" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              classes={{
                indicator: "tabs",
              }}
            >
              <Tab
                label="Informacje"
                {...a11yProps(0)}
                className="tab hover_tab"
                classes={{ selected: "selectedTab" }}
              />
              <Tab
                label="Menu - Zamów"
                {...a11yProps(1)}
                className="tab hover_tab"
                classes={{ selected: "selectedTab" }}
              />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0} component="div">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <RestaurantInfoWrapper>
                  <p>
                    <strong>
                      {dataRestaurant?.closed ? "Zamknięta" : "Otwarta"}
                    </strong>
                  </p>
                  <p>Dostawa: <strong>{dataRestaurant?.available_for_delivery ? "Tak" : "Nie"}</strong></p>
                  <p>Minimalna kwota: <strong>{getPrice(dataRestaurant?.minimum_order_amount)}</strong></p>
                </RestaurantInfoWrapper>
                <h3 className="restaurant__title">
                  {dataRestaurant.name && dataRestaurant.name}
                </h3>
                <p
                  className="restaurant__desc"
                  dangerouslySetInnerHTML={{
                    __html:
                      dataRestaurant.description && dataRestaurant.description,
                  }}
                />
                <p
                  className="restaurant__desc"
                  dangerouslySetInnerHTML={{
                    __html:
                      dataRestaurant.information && dataRestaurant.information,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={
                    dataRestaurant.media.length
                      ? dataRestaurant.media[0].url
                        ? dataRestaurant.media[0].url.split(".pl").join(".pl/")
                        : TopImg
                      : TopImg
                  }
                  width="100%"
                  className="restaurant__img"
                  alt=""
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {[
                {
                  name: getOpeningDay(
                      dataRestaurant?.timings.title,
                      dataRestaurant?.timings.opening_time,
                      dataRestaurant?.timings.closing_time,
                      dataRestaurant?.timings.is_closed
                  ),
                  content: "",
                  imgSrc: "",
                },
                {
                  imgSrc: Return,
                  name: dataRestaurant.address
                      ? dataRestaurant.address.split(",")[0]
                      : "",
                  content: dataRestaurant.address
                      ? dataRestaurant.address.split(",")[1]
                      : "",
                },
                {
                  imgSrc: Phone,
                  name: dataRestaurant.phone ? dataRestaurant.phone : "",
                  content: ``,
                },
              ].map((item, index) => {
                if(item.name){
                  return <Grid key={index} item xs={12} sm={6} md={4}>
                    <ResInformation
                        cardAddress={index === 1}
                        name={item.name ?? ''}
                        content={item.content ?? ''}
                        imgSrc={item.imgSrc}
                    />
                  </Grid>;
                }
              })}
            </Grid>
            <div className="d-flex mt40 mb20">
              <img src={Note} className="mr10" alt="star" />
              <Typography className="top">Opinie</Typography>
            </div>
            <Grid container spacing={4}>
              {reviews
                .filter((item) => item.restaurant_id === Number(id))
                .slice(0, 3)
                .map((review) => (
                  <Grid key={review.id} item lg={4} md={4} sm={6} xs={12}>
                    <Opinion
                      avatar={review.user_id}
                      name={review.user.name}
                      content={review.review}
                      rate={review.rate}
                      restaurantId={review.restaurant_id}
                    />
                  </Grid>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MenuRestaurant />
          </TabPanel>
        </div>
      ) : (
        <div style={{textAlign: "center"}}>
          <CircularProgress size={20} />
        </div>
      )}
    </div>
  );
};

export default Restaurant;
