import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import "../Cart/styles.scss";
import CartSuccess from "../Cart/CartSuccess";
import Breadcrumb from "../../Components/Breadcrumbs";
import {useDispatch, useSelector} from "react-redux";


const AntTab = withStyles((theme) => ({
    root: {
        textTransform: "none",
        "&$selected": {
            backgroundColor: "#d1402a",
            color: "#FFFFFF !important",
        },
    },
    selected: {},
}))((props) => <Tab {...props} />);

const getId = (index) => {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
};

const AfterPayment = () => {
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(123);
    const [value, setValue] = useState(0);
    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="cart_page fade-in">
            <div className="container">
                <Breadcrumb title="Koszyk" />

                <div className="tabs">
                    <Tabs
                        scrollButtons="off"
                        orientation="horizontal"
                        variant="scrollable"
                        value={value}
                        onChange={handleChangeTab}
                        aria-label="Vertical tabs example"
                        className="tabs__menu"
                    >
                        <AntTab
                            className="tabs__menu--children hover_tab"
                            label="Zamówienie"
                            disabled={true}
                            {...getId(0)}
                        />
                        <AntTab
                            className="tabs__menu--children hover_tab"
                            label="Dostawa"
                            disabled={true}
                            {...getId(1)}
                        />
                        <AntTab
                            className="tabs__menu--children hover_tab"
                            label="Płatność"
                            disabled
                            {...getId(2)}
                        />
                    </Tabs>
                </div>
                <CartSuccess order={success} />
            </div>
        </div>
    );
};

export default AfterPayment;
