import axiosClient from "./axiosClient";

const restaurantApi = {
  getAll: (params) => {
    if(params){
      params.domain = 1;
    }
    else{
      params = {domain: 1};
    }
    const url = '/restaurants';
    return axiosClient.get(url, {params})
  },

  getSingle: (id) => {
    const url = `/restaurants/${id}`;
    return axiosClient.get(url);
  }
}

export default restaurantApi;
