import axiosClient from "./axiosClient";

const faqApi = {
  getFaqs: (params) => {
    const url = "/faqs";
    if(params){
      params.domain = 1;
    }
    else{
      params = {domain: 1};
    }
    return axiosClient.get(url, { params });
  },
  getFaqCategories: (params) => {
    const url = "/faq_categories";
    if(params){
      params.domain = 1;
    }
    else{
      params = {domain: 1};
    }
    return axiosClient.get(url, {params})
  }
};

export default faqApi;
