import React, {useEffect, useState} from "react";
import SearchBox from "../../Components/SearchBox";
import Star from "../../Assets/Home/star.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardReview from "../../Components/CardReview";
import TopImage from "../../Assets/Home/top.jpg";
import {useHistory, useLocation} from "react-router-dom";
import restaurantApi from "../../Api/restaurantApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import "./styles.scss";

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TypographyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const RestaurantsList = () => {
    const history = useHistory();
    const location = useLocation();
    const [restaurants, setRestaurants] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        const query = new URLSearchParams(location.search);
        //let search = query.get('search');
        let delivery = "";
        if(query.has('delivery')){
            delivery = query.get('delivery') === 'true' ? ";available_for_delivery:1" : "";
        }
        const params = {
            search: delivery,
            searchFields: 'address:like',
        }
        if(query.has('open')){
            params.is_open = query.get('open') === 'true';
        }
        if(query.has('open')){
            params.is_open = query.get('open') === 'true';
        }
        if(query.has('lat')){
            params.lat = query.get('lat');
        }
        if(query.has('lng')){
            params.lng = query.get('lng');
        }
        restaurantApi.getAll(params).then((response)=>{
            const {data: newRestaurants} = response;
            setRestaurants(newRestaurants);
        }).finally(()=>{
            setLoader(false);
        })
    }, [location.search]);

    return (
        <>
            <div className="searchArea">
                <SearchBox
                    title="Poszukaj Restauracji,"
                    subtitle="z której chcesz zamówić jedzenie"
                />
            </div>
            <div className="reviewArea">
                <div className="container">
                    <div className="d-flex mb30">
                        <img src={Star} className="mr10" alt="star rate" />
                        <TypographyWrapper>
                            <Typography className="top">Restauracje</Typography>
                            <Typography className="top" onClick={()=>{
                                history.push(`/restaurants?search=`);
                            }}>Wszystkie ></Typography>
                        </TypographyWrapper>
                    </div>
                    {loader && (
                        <LoaderWrapper>
                            <CircularProgress color="secondary" />
                        </LoaderWrapper>
                    )}
                    <Grid container spacing={3}>
                        {restaurants.map((restaurant) => (
                                <Grid key={restaurant.id} item lg={3} md={4} sm={6} xs={12}>
                                    <CardReview
                                        imgSrc={
                                            restaurant.media.length
                                                ? restaurant.media[0].url
                                                ? restaurant.media[0].url.split(".pl").join(".pl/")
                                                : TopImage
                                                : TopImage
                                        }
                                        name={restaurant.name}
                                        id={restaurant.id}
                                        rate={restaurant.rate}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default RestaurantsList;
