import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import _ from "lodash";
import { useSelector } from "react-redux";
import image from "../../Assets/Onas/onas_background.png"
import "./style.scss";

const ONas = () => {
    const oNas = useSelector((state) => state.otherReducer.o_nas);
    return (
        <div className="onas">
            <div className="container">
                <Breadcrumb title="O nas" />
                <div className="onas--wrapper">
                    <div className="onas--content">
                        {!_.isEmpty(oNas) && (
                            <>
                                <div dangerouslySetInnerHTML={{ __html: oNas.question }} />
                                <div dangerouslySetInnerHTML={{ __html: oNas.answer }} />
                            </>
                        )}
                    </div>
                    <div>
                        <img className="onas--image" src={image} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ONas;
