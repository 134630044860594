import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {calcDistance} from "../../helpers/cart";
import {cart} from "../../Models/Cart";
import {getPrice} from "../../helpers/order";
import restaurantApi from "../../Api/restaurantApi";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ModalLogin from "../../Components/ModalLogin";
import Modal from "@material-ui/core/Modal";
import {makeStyles} from "@material-ui/core/styles";
import ModalRegulation from "../../Components/ModalLogin/ModalRegulation";
import styled from 'styled-components';

const totalPrice = (arr, shipping) => {
  return arr.reduce((total, item) => {
    return total + parseFloat(item.sum);
  }, 0) + (shipping ?? 0.0);
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const StyledRegulationSpan = styled.span`
  color: #d1402a !important;
  cursor: pointer;
`;

const CartMoney = ({ idPanel, changeTab, formValues, shipping, setShipping }) => {
  const classes = useStyles();
  const carts = useSelector((state) => state.cartReducer.cart);
  const location = useSelector((state) => state.userReducer.location);
  const userReducer = useSelector((state) => state.userReducer);
  const [lat, setLat] = useState(location?.latitude);
  const [lng, setLng] = useState(location?.longitude);
  const [restaurantLat, setRestaurantLat] = useState(null);
  const [restaurantLng, setRestaurantLng] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRegulation, setOpenModalRegulation] = useState(false);
  const [allowOrder, setAllowOrder] = useState(false);
  const [checked, setChecked] = useState(false);

  const getUserPos = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position => {
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      }),(error)=>console.log(error));
    } else {
      toast.error("Geolokalizacja jest wymagana do zamówienia");
    }
  }

  const canGoToShip = (total) => {
    if(shipping == null){
      return false;
    }
    if(restaurant.closed){
      toast.error("Restauracja jest zamknięta");
      return false;
    }
    if(restaurant.minimum_order_amount > total){
      toast.error(`Minimalna kwota zamówienia to ${restaurant.minimum_order_amount} PLN`);
      return false;
    }
    return true;
  }

  const goToShip = (total) => {
    if(canGoToShip(total)){
      changeTab(1)
    }
  }

  const goToPayTab = () => {
    const {shippingMethod, name, city, phone, address, email} = formValues;
    if(shippingMethod !== "store"){
      if(name !== "" && city !== "" && phone !== "" && address !== "" && email !== ""){
        changeTab(2)
      }
      else{
        toast.error("Uzupełnij wymagane dane");
      }
    }
    else{
      changeTab(2);
    }
  }

  useEffect(() => {
    if(carts.length){
      if(restaurant == null || (carts[0]?.food.restaurant_id !== restaurant.id)){
        restaurantApi.getSingle(carts[0]?.food.restaurant_id).then((response) => {
          setRestaurant(response.data);
        });
      }
      getUserPos();
      if(restaurantLat !== carts[0].food.restaurant.latitude){
        setRestaurantLat(carts[0].food.restaurant.latitude);
      }
      if(restaurantLng !== carts[0].food.restaurant.longitude){
        setRestaurantLng(carts[0].food.restaurant.longitude);
      }

      const distance = calcDistance(lat,lng,restaurantLat,restaurantLng);
      if(distance && restaurant != null){
        setShipping(cart.calcShippingPrice(restaurant, distance, totalPrice(carts)));
        setAllowOrder(!distance <= restaurant.delivery_range)
      }
    }
  }, [lat, lng, restaurant, shipping, carts]);


  return (
      <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={() => setOpenModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={openModal}>
            <ModalLogin setOpenModal={setOpenModal} />
          </Fade>
        </Modal>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModalRegulation}
            onClose={() => setOpenModalRegulation(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={openModalRegulation}>
            <ModalRegulation regulation={openModalRegulation} />
          </Fade>
        </Modal>
        <div className="cart_money fade-in">
          <Card>
            <div className="card_money--info">
              <div className="total">
                <span>Razem</span>
                <span className="text-bold">{getPrice(totalPrice(carts))}</span>
              </div>
              {restaurant != null && calcDistance(lat,lng,restaurantLat,restaurantLng) <= restaurant.delivery_range && (
                  <>
                    {restaurant.available_for_delivery && (
                        <div className="extra">
                          <span>Dostawa</span>
                          <span>
                        {getPrice(shipping)}
                      </span>
                        </div>
                    )}
                  </>
              )}
              {(restaurant != null && restaurant.regulations !== null) && (
                  <div className="total">
                    <input type="checkbox"
                           checked={checked}
                           onChange={() => {
                             if(idPanel === 0){
                               setChecked(!checked)
                             }
                           }}
                    />
                    <span>Akceptuje <StyledRegulationSpan onClick={()=>{
                      setOpenModalRegulation(restaurant.regulations);
                    }}>regulamin</StyledRegulationSpan> zamówienia</span>
                  </div>
              )}
            </div>
            {carts.length ? (
                idPanel === 0 ? (
                    <Button
                        type="button"
                        className="cardBtn"
                        onClick={() => {
                          if(userReducer.id){
                            goToShip(totalPrice(carts));
                          }
                          else{
                            setOpenModal(true);
                          }
                        }}
                        disabled={allowOrder && (!checked)}
                    >
                      <div className="text_left">
                        <ArrowRightAlt fontSize="default" />
                        <span>Dalej</span>
                      </div>
                      <span>{getPrice(totalPrice(carts, shipping))}</span>
                    </Button>
                ) : (
                    <>
                      {idPanel === 1  && (
                          <Button className="cardBtn" type="button" onClick={() => goToPayTab()}>
                            <div className="text_left">
                              <ShoppingCart fontSize="default"/>
                              <span>Zamawiam i płacę</span>
                            </div>
                            <span>{getPrice(totalPrice(carts, shipping))}</span>
                          </Button>
                      )}
                    </>
                )
            ) : (
                ""
            )}
          </Card>
        </div>
      </>
  );
};

export default CartMoney;
