import React, {useEffect, useRef, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import ArrowForward from "@material-ui/icons/ArrowForward";
import SearchIcon from "@material-ui/icons/Search";
import MapPicker from "../../Assets/Home/map.svg";
import "./styles.scss";
import {useHistory, useLocation} from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import {geocode} from "../../Models/Geocode";
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const BlackSwitch = withStyles({
  switchBase: {
    color: '#000000',
    '&$checked': {
      color: '#000000',
    },
    '&$checked + $track': {
      backgroundColor: '#000000',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ButtonsWrapper = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;


const SearchBox = (props) => {
  const {title, subtitle} = props;
  const location = useLocation();
  const history = useHistory();
  const [keySearch, setKeySearch] = useState('');
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [delivery, setDelivery] = useState(true);
  const [open, setOpen] = useState(true);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.has('delivery')) {
      setDelivery(query.get('delivery') === 'true');
    }
    if (query.has('open')) {
      setOpen(query.get('open') === 'true');
    }
    if (query.has('lat')) {
      setLat(query.get('lat'));
    }
    if (query.has('lng')) {
      setLng(query.get('lng'));
    }
    if (query.has('search')) {
      setKeySearch(query.get('search'));
      setLoad(true);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position => {
          geocode.getAddress(position.coords.latitude, position.coords.longitude,
              (response) => {
                const address = response.results[0].formatted_address;
                setLat(response.results[0].geometry.location.lat);
                setLng(response.results[0].geometry.location.lng);
                setKeySearch(address);
              },
              (error) => {
                console.error(error);
                setKeySearch('');
              }, () => setLoad(true)
          );
        }), (error) => console.log(error));
      }
    }
  }, []);

  const onSubmitForm = (e) => {
    e.preventDefault();
    if(lat && lng){
      history.push(`/restaurants?open=${open}&delivery=${delivery}&lat=${lat}&lng=${lng}&search=${keySearch.trim()}`);
    }
    else{
      history.push(`/restaurants?open=${open}&delivery=${delivery}&search=${keySearch.trim()}`);
    }
  };

  const toggleDelivery = (e) => {
    setDelivery(e.target.checked);
  }

  const toggleOpen = (e) => {
    setOpen(e.target.checked);
  }

  const onChangeAddress = async (e) => {
    await geocodeByPlaceId(e.value.place_id)
        .then(results => {
          setLat(results[0].geometry.location.lat())
          setLng(results[0].geometry.location.lng())
        })
        .catch(error => console.error(error));
    setKeySearch(e.label);
  }

  return (
      <div className="container">
        <Grid
            item={true}
            sm={12}
            xs={6}
            lg={6}
            style={{margin: "auto"}}
            className="searchBox"
        >
          <Typography className="title">{title}</Typography>
          <Typography className="subTitle">{subtitle}</Typography>
          <form onSubmit={onSubmitForm}>
            <div className="search">
              <div className="searchIcon">
                <SearchIcon classes={{root: "searchColor"}}/>
              </div>
              {!load && (
                  <InputBase
                      placeholder="Podaj swój adres"
                      inputProps={{"aria-label": "search"}}
                      onChange={(e) => {
                        setKeySearch(e.target.value);
                      }}
                      value={keySearch}
                      classes={{
                        root: "inputSearch",
                        input: "inputInput",
                      }}
                      name="search"
                  />
              )}
              {load && (
                  <GooglePlacesAutocomplete
                      apiKey="AIzaSyCIC2lPsBJe8HDVD_TaPbSaqoXXYaxGeAg"
                      selectProps={{
                        name: "search",
                        keySearch,
                        onChange: onChangeAddress,
                        placeholder: 'Podaj swój adres',
                        defaultInputValue: keySearch
                      }}
                      minLengthAutocomplete={3}
                      apiOptions={
                        {
                          language: 'pl',
                          region: 'pl'
                        }
                      }
                  />
              )}

            </div>
            <div className="button">
              <FormControlLabel
                  className="switchInput"
                  control={<BlackSwitch checked={delivery} onChange={toggleDelivery}/>}
                  label={delivery ? "Dostawa" : "Odbiór"}
              />

              <FormControlLabel
                  className="switchInput"
                  control={<BlackSwitch checked={open} onChange={toggleOpen}/>}
                  label={open ? "Wszystkie" : "Otwarte"}
              />
            </div>
            <ButtonsWrapper>
              <div className="button">
                <Button className="searchBtn" type="submit">
                  SZUKAJ
                </Button>
                <div className="arrow">
                  <ArrowForward
                      classes={{
                        root: "arrowRight",
                      }}
                  />
                </div>
              </div>

              <div className="button">
                <div className="mapPicker">
                  <img src={MapPicker} alt="map_picker"/>
                </div>
                <Button className="searchBtn" type="button" onClick={() => {
                  history.push(`/maps?search=${keySearch.trim()}&open=${open}&delivery=${delivery}`);
                }}>
                  lub szukaj po mapie
                </Button>
                <div className="arrow">
                  <ArrowForward
                      classes={{
                        root: "arrowRight",
                      }}
                  />
                </div>
              </div>
            </ButtonsWrapper>
          </form>
        </Grid>
      </div>
  );
};

export default SearchBox;
