import React from "react";
import Breadcrumb from "../../Components/Breadcrumbs";
import image from "../../Assets/Beneftis/benefits.png"
import {
    ChevronRight
} from "@material-ui/icons";

import "./style.scss";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 1000px;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-position: right;

  display: flex;
`;

const Content = styled.div`
  background-color: #d1402a;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin: 20px;
  padding: 20px;
  width: 500px;
`;

const ContentText = styled.div`
  color: white;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
`;

const info = [
    'Brak stałych kosztów',
    'Niska prowizja od zamówienia',
    'Pozyskanie nowych klientów dla restauracji',
    'Rezerwacja stolików',
    'Wyszukiwanie restauracji po mapie',
    'Rozbudowany Panel Restauracji',
    'Czytelne warunki umowy'
]


const Benefit = () => {
    return (
        <div className="benefit">
            <div className="container">
                <Breadcrumb title="Korzyści dla Restauracji"/>
                <Wrapper>
                    <Content>
                        {info.map(item => (
                            <ContentText>
                                <ChevronRight/>
                                <span>
                                    {item}
                                </span>
                            </ContentText>
                        ))}
                    </Content>
                </Wrapper>
            </div>
        </div>
    );
};

export default Benefit;
