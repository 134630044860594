import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import VisibilityIcon from "@material-ui/icons/Visibility";

// Styles
import "./menuCard.scss";
import { Link } from "react-router-dom";
import {displayPrice} from "../../helpers/price";
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  font-weight: 700 !important;
  font-size: 15px !important;
`;

const MenuCard = (props) => {
  const { imgSrc, price, name, desc, restaurantId, id } = props;

  return (
    <Link to={`/restaurants/${restaurantId}/food#${id}`} style={{ textDecoration: "none", color: "#000" }}>
      <Grid container className="menu-card">
        <Grid item xs={3}>
          <img src={imgSrc} width="100%" height="80px" alt="" />
        </Grid>
        <Grid item xs={6} className="menu-card__text">
          <Typography>{name}</Typography>
          <p dangerouslySetInnerHTML={{__html: desc}}/>
        </Grid>
        <Grid item xs={3} className="menu-card__price">
          <VisibilityIcon classes={{ root: "eyeColor" }} />
          <StyledTypography>{displayPrice(price)} PLN</StyledTypography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default MenuCard;
